import $axios from '@/utils/request'
// 获取服务器文件内容
export function getFileText(url) {
    return $axios.fGetFileText(url, {})
}

// 上传作品通用接口
export function uploadFile(data) {
    return  $axios.fInfoPost("/common/file/uploadFile", data || {})
}

// 上传图片
export function uploadMaps (data) {
    const url = '/common/file/uploadMaps'
    return $axios.fInfoPost(url, data)
  }

