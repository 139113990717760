import $axios from '@/utils/request'

// 获取作品列表
export function $getWorksCreationList(data) {
    const url = '/creation/getList'
    return $axios.fPost(url, data)
}

// 查看作品查看举报详情
export function $getWorkslistCreationItemInfo(data) {
  const url = '/creation/listCreationItem'
  return $axios.fPost(url, data)
}

// 作品禁用

export function $getWorksDisabled(data) {
  const url = '/creation/disabled'
  return $axios.fPost(url, data)
}

// 取消作品查看举报业务
export function $getWorksCancleChangeCreationNum(data) {
  const url = '/creation/cancleChangeCreationNum'
  return $axios.fPost(url, data)
}

// /openplatform/creation/getInformation
// 作品信息
export function $getInformation(data) {
  const url = '/openplatform/creation/getInformation'
  return $axios.fInfoGet(url, data)
}

//--------------------------------------------------------------------------------------------------
// 适当修改 复制tms
// 保存作品
export function saveProject(data) {
    return $axios.fPost("/creation/add", data || {})
}
// 获取作品列表
export function getWorksList(data) {
    return $axios.fPost("/creation/list", data || {})
}
// 获取作品详情
export function getWorkInfo(data) {
    return $axios.fGet("/creation/getInformation", {creationId: data.id} || {})
}
export function getWorkInfoId(data) {
    return $axios.fGet("/creation/getInformation", {id: data.id} || {})
}
// 编辑作品接口
export function editWorkInfo(data) {
    return $axios.fPost("/creation/edit", data || {})
}

// 点赞查看评论收藏
export function editWorkItem(data) {
    return $axios.fPost("/creation/changeCreationItem", data || {})
}
// 检查用户是否对作品点赞
export function getVoteStatus(data) {
    return $axios.fPost("/creation/showUpvote", data || {})
}
//展示活动及奖项

export function getAwardInformation(data) {
    return $axios.fGet("/creation/getAwardInformation", data || {})
}

// 保存作品
export function saveTaskProject(data) {
    return $axios.fPost("/course/creation/saveCreation", data || {})
}

export function getScratchJrList(data) {
    const url = '/course/creation/getList'
    return $axios.fPost(url, data)
}