import $axios from '@/utils/request'

// 获取scratch模板列表
export function scratchmoduleList (data) {
  const url = '/srcTpl/list'
  return $axios.fPost(url, data)
}

// 删除模板
export function scratchmoduleDelect (data) {
  const url = '/srcTpl/del'
  return $axios.fGet(url, data)
}
// 保存模板
export function saveMould(data) {
  const url = '/srcTpl/upload'
  return $axios.fPost(url, data)
}

// 获取任务下提交的作品列表
export function scratchList (data) {
  const url = '/task/scratchList'
  return $axios.fPost(url, data)
}


// 获取作品详情
export function getWorkInfo(data) {
  return $axios.fGet("/creation/getInformation", data || {})
}

//获取模板
export function getSrcTplInfo(data) {
  const url = '/srcTpl/info'
  return $axios.fGet(url, data)
}


//获取scratchjr模板列表
export function getScratchJrList(data) {
  const url = '/course/creation/getList'
  return $axios.fPost(url, data)
}