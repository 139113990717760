<template>
  <div class="box-code" v-loading="loading">
    <div class="navs" style="background-color: #f5c319">
      <el-button
        v-if="button.works"
        type="primary"
        @click="$router.push({ name: 'works' })"
        :size="size"
      >
        作品中心
      </el-button>
      <el-dropdown v-if="button.file" trigger="hover" @command="handleCommands">
        <span>
          <el-button type="primary" :size="size">
            文件操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a">新建</el-dropdown-item>
          <input
            style="display: none"
            @change="(e) => inputChange(e)"
            type="file"
            accept=".sjr"
            ref="newfileinput"
          />
          <el-dropdown-item command="b">导入</el-dropdown-item>
          <el-dropdown-item command="c">导出</el-dropdown-item>
          <el-dropdown-item command="d">另存为</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-input
        style="display: inline-block; width: 200px"
        class="gullters"
        v-model="formInfo.title"
        :size="size"
        placeholder="请输入作品名称"
      ></el-input>
      <el-button
        class="gullters"
        style="display: inline-block"
        @click="openWinEditor('save')"
        type="primary"
        :size="size"
        >保存</el-button
      >
    </div>
    <iframe
      class="iframe-style"
      :src="iframeWinPaht"
      frameborder="0"
      ref="iframes"
      id="scratchjriframe"
    />
    <editor
      @saveFile="saveFile"
      :formInfos="formInfo"
      :rules="rules"
      ref="editor"
    />
  </div>
</template>
<script>
import {
  editWorkInfo,
  saveProject,
  getWorkInfo,
  getWorkInfoId,
} from "@/api/works/index";
import { saveMould } from "@/api/research/scratch";
import { uploadFile, getFileText } from "@/api/works/file";
import Editor from "./components/editor.vue";
export default {
  name: "ScratchJrMould",
  components: {
    Editor,
  },
  data() {
    return {
      size: "small",
      button: {
        works: false, // 作品中心按钮
        file: false, // 文件操作按钮
        list: false, // 作品列表按钮
      },
      iframeWin: {},
      iframeWinPaht:
        process.env.VUE_APP_PREVIEW_SCRATCHJR +
        "/editor.html?pmd5=" +
        uuid() +
        "&mode=edit",
      scratchJrLoading: false,
      activeIndex: "1",
      loading: false, //编辑器
      saveAs: false,
      saveType: "save",
      formInfo: {
        title: "", //	是	String	作品名称
        description: "", //		否	String	简介
        cover: "", //		否	String	封面
        label: "预置代码", //		否	String	标签 (‘游戏’)
        category: "ScrachJr", //		是	String	类别(‘图形化编程’,’Python’,’Microbit’)
        source: "模板", //		是	String	来源(‘活动’,’创作’,’课程’)
        content: "", //		否	String	内容
        type: "文件", //		是	String	类型(‘文件’,’代码’）,类型为“文件”时，将文件路径置于sourceCodeFile,类型为“代码”时，将代码内容置于content
        sourceCodeFile: "", //		否	String	源代码文件的路径
        videoFile: "", //		否	String	作品视频简介
      },
      rules: {
        title: [
          { required: true, message: "作品名称不可为空", trigger: "blur" },
        ],
        description: [
          { required: false, message: "作品描述", trigger: "blur" },
        ],
      },
      PythonList: [],
      initFlag: true,
      finishFlag: true,
    };
  },
  mounted() {
    this.iframeWin = document.getElementById("scratchjriframe").contentWindow;
    window.addEventListener("message", this.handleMessage, true);
    if (this.$route.query.id) {
      this.formInfo.id = this.$route.query.id;
    }
    if (this.$route.query.title) {
      this.formInfo.title = this.$route.query.title;
    }
    if (this.$route.query.description) {
      this.formInfo.description = this.$route.query.description;
    }
    this.loading = true;
    const _this = this;
    timer();
    function timer() {
      setTimeout(() => {
        if (_this.finishFlag) {
          timer();
          return;
        }
        if (_this.$route.query.id) {
          _this.iframeWin.postMessage(
            { type: "initData", data: { id: _this.$route.query.id } },
            "*"
          );
          _this.loading = false;
        } else {
          _this.newFile();
        }
      }, 2000);
    }
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage, true);
  },
  methods: {
    inputChange(e) {
      this.formInfo.description = null;
      this.formInfo.id = null;
      if (e.target.files.length > 0) {
        this.iframeWin.postMessage(
          { type: "openLoacalProject", data: { file: e.target.files[0] } },
          "*"
        );
      }
    },
    openWinEditor(str) {
      this.saveType = str;
      this.$refs.editor.open();
    },
    // 初次获取详情信息
    getWorkInfo(id) {
      this.loading = true;
      getWorkInfo({
        id,
      })
        .then((res) => {
          this.formInfo = res.body;
          if (res.body.sourceCodeFile) {
            this.iframeWin.postMessage(
              {
                type: "loadProjectSjr",
                data: { url: res.body.sourceCodeFile },
              },
              "*"
            );
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleMessage(e) {
      switch (e.data.type) {
        case "onSaveCloud":
          this.saveFileList(e.data.data, (coverUrl, fileUrl) => {
            this.formInfo.sourceCodeFile = fileUrl;
            this.formInfo.cover = coverUrl;
            this.$nextTick(() => {
              if (this.formInfo.id) {
                this.editWorkInfo(this.formInfo);
              } else {
                this.saveProject(this.formInfo);
              }
            });
          });
          break;
        case "onSaveCloudss":
          this.saveFileList(e.data.data, (coverUrl, fileUrl) => {
            if (!fileUrl) {
              return;
            }
            this.formInfo.sourceCodeFile = fileUrl;
            this.formInfo.cover = coverUrl;
            this.$nextTick(() => {
              fetch(fileUrl)
                .then((res) => {
                  return res.blob();
                })
                .then((blob) => {
                  const file = new File([blob], this.formInfo.title + ".sjr", {
                    type: blob.type,
                  });
                  const formData = new FormData();
                  formData.append("type", "scratchjr");
                  formData.append("file", file);
                  if (this.$route.query.id) {
                    formData.append("id", this.$route.query.id);
                  }
                  saveMould(formData)
                    .then((res) => {
                      this.$message.success("提交成功");
                      this.$refs.editor.close();
                      this.loading = false;
                      this.formInfo.id = res.body.id;
                    })
                    .catch(() => {
                      this.loading = false;
                    });
                })
                .catch(() => {
                  this.loading = false;
                });
            });
          });
          break;
        case "onSaveOther":
          this.saveFileList(e.data.data, (coverUrl, fileUrl) => {
            if (!fileUrl) {
              return;
            }
            this.formInfo.sourceCodeFile = fileUrl;
            this.formInfo.cover = coverUrl;
            this.$nextTick(() => {
              this.saveProject({ ...this.formInfo });
            });
          });
          break;
        case "newProjectFinish":
          if (this.$route.query.id) {
            this.finishFlag = false;
            setTimeout(() => {
              // this.iframeWin.postMessage(
              //   {
              //     type: "loadProjectSjr",
              //     data: { url: this.$route.query.file },
              //   },
              //   "*"
              // );

              this.getWorkInfo(this.$route.query.id);
            }, 1000);
          } else {
            this.loading = false;
          }
          break;
        case "loadProjectFinish":
          this.loading = false;
          break;
        case "initScratchJrProject":
          this.finishFlag = false;
          // if (this.$route.query.id && this.initFlag) {
          //   this.initFlag = false;
          //   this.getWorkInfo(this.$route.query.id);
          // } else {
          //   this.newFile();
          // }
          break;
        default:
          break;
      }
    },
    //监听文件操作
    handleCommands(str) {
      this.$nextTick(() => {
        switch (str) {
          case "a":
            this.newFile();
            break;
          case "b":
            this.$refs.newfileinput.click();
            break;
          case "c":
            this.exportFile();
            break;
          case "d":
            this.openWinEditor("other");
            break;
          default:
            break;
        }
      });
    },
    convertBase64UrlToBlob(urlData) {
      //base64转化为blob
      var bytes = window.atob(urlData.split(",")[1]); //去掉url的头，并转换为byte

      //处理异常,将ascii码小于0的转换为大于0
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab]);
    },
    async saveFileList(props, cb) {
      let coverUrl = await this.uploadJrFile(props.cover);
      let fileUrl = await this.uploadJrFile(props.file);
      cb(coverUrl.body.fileUrl, fileUrl.body.fileUrl);
    },
    uploadJrFile(file) {
      let params = new FormData();
      params.append("file", file);
      return uploadFile(params);
    },
    // 保存代码
    saveFile(form) {
      this.loading = true;
      this.formInfo = {
        ...this.formInfo,
        title: form.title,
        description: form.description,
        source: this.$route.query.source || this.formInfo.source,
      };
      if (this.saveType === "save") {
        this.onSaveCloud();
      } else if (this.saveType === "other") {
        this.onSaveOther();
      }
    },
    editWorkInfo(props) {
      editWorkInfo({
        id: props.id,
        title: props.title,
        description: props.description,
        category: "ScratchJr",
        source: props.source,
        type: "文件",
        sourceCodeFile: props.sourceCodeFile,
        cover: props.cover,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success("提交成功");
          this.$refs.editor.close();
        })
        .catch((err) => {
          this.loading = false;
          this.$message.warning("提交失败");
        });
    },
    saveProject(props) {
      saveProject({
        title: props.title,
        description: props.description,
        category: "ScratchJr",
        source: props.source,
        type: "文件",
        sourceCodeFile: props.sourceCodeFile,
        cover: props.cover,
      })
        .then((res) => {
          this.$message.success("提交成功");
          this.$refs.editor.close();
          this.loading = false;
          this.formInfo.id = res.body.id;
        })
        .catch((err) => {
          this.loading = false;
          this.$message.warning("提交失败");
        });
    },
    //新建作品
    newFile() {
      this.loading = false;
      this.formInfo.title = null;
      this.formInfo.id = null;
      this.formInfo.description = null;
      this.iframeWin.postMessage({ type: "newProject" }, "*");
    },
    //下载作品
    exportFile() {
      this.iframeWin.postMessage({ type: "saveLoacalProject" }, "*");
    },
    // 保存
    onSaveCloud() {
      this.iframeWin.postMessage({ type: "onSaveCloud" }, "*");
    },
    //另存为
    onSaveOther() {
      this.loading = true;
      this.iframeWin.postMessage({ type: "onSaveOther" }, "*");
    },
  },
};
</script>
<style scoped lang='scss'>
.iframes {
  width: calc(100vw);
  height: calc(100vh);
  overflow: hidden;
}
</style>
<style>
.el-loading-mask {
  z-index: 9999;
}
.box-code {
  height: calc(100vh);
  width: calc(100vw);
  overflow: hidden;
}
.iframe-style {
  width: 100%;
  height: calc(100vh - 44px);
}
.iframe-style::-webkit-scrollbar {
  display: none;
}

.nav {
  height: 40px;
  padding-top: 4px;
  padding-left: 10px;
  /* background-color: #121212; */
  /* background-image: linear-gradient(to right, #ffe02a, #f5c319, #e6a23c); */
}

.navs {
  height: 40px;
  padding-top: 4px;
  padding-left: 40px;
}

.gullters {
  margin-left: 10px;
}
/*
.el-dropdown-menu{
  display: block !important;
} */
</style>
