var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "box-code",
    },
    [
      _c(
        "div",
        { staticClass: "navs", staticStyle: { "background-color": "#f5c319" } },
        [
          _vm.button.works
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: _vm.size },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "works" })
                    },
                  },
                },
                [_vm._v(" 作品中心 ")]
              )
            : _vm._e(),
          _vm.button.file
            ? _c(
                "el-dropdown",
                {
                  attrs: { trigger: "hover" },
                  on: { command: _vm.handleCommands },
                },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "el-button",
                        { attrs: { type: "primary", size: _vm.size } },
                        [
                          _vm._v(" 文件操作 "),
                          _c("i", {
                            staticClass: "el-icon-arrow-down el-icon--right",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "a" } }, [
                        _vm._v("新建"),
                      ]),
                      _c("input", {
                        ref: "newfileinput",
                        staticStyle: { display: "none" },
                        attrs: { type: "file", accept: ".sjr" },
                        on: { change: (e) => _vm.inputChange(e) },
                      }),
                      _c("el-dropdown-item", { attrs: { command: "b" } }, [
                        _vm._v("导入"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "c" } }, [
                        _vm._v("导出"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "d" } }, [
                        _vm._v("另存为"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("el-input", {
            staticClass: "gullters",
            staticStyle: { display: "inline-block", width: "200px" },
            attrs: { size: _vm.size, placeholder: "请输入作品名称" },
            model: {
              value: _vm.formInfo.title,
              callback: function ($$v) {
                _vm.$set(_vm.formInfo, "title", $$v)
              },
              expression: "formInfo.title",
            },
          }),
          _c(
            "el-button",
            {
              staticClass: "gullters",
              staticStyle: { display: "inline-block" },
              attrs: { type: "primary", size: _vm.size },
              on: {
                click: function ($event) {
                  return _vm.openWinEditor("save")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("iframe", {
        ref: "iframes",
        staticClass: "iframe-style",
        attrs: {
          src: _vm.iframeWinPaht,
          frameborder: "0",
          id: "scratchjriframe",
        },
      }),
      _c("editor", {
        ref: "editor",
        attrs: { formInfos: _vm.formInfo, rules: _vm.rules },
        on: { saveFile: _vm.saveFile },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }